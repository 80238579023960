
import { Component, Mixins, Emit } from "vue-property-decorator";
import Singlepages from "@/mixins/SinglepagesJa";
import PageHeader from "@/components/page/PageHeader.vue";

@Component({
  components: {
    PageHeader,
  },
})
export default class Campaign extends Mixins(Singlepages) {
  private name = "campaign";
  private newentriesLabel = "新着記事";
}
