
import { Vue, Component, Mixins, Emit } from 'vue-property-decorator';
import LayoutCol1 from '@/components/layout/LayoutCol1.vue';
import axios, {AxiosResponse, AxiosError} from 'axios';
import HomeCampaignlistItem from '@/components/home/HomeCampaignlist/HomeCampaignlistItem.vue';
import $ from 'jquery';
import {Route} from 'vue-router';
import store from '@/store';
import NaviArchive from '@/components/navi/NaviArchive.vue';
import BoxSpecialofferLink from '@/components/box/BoxSpecialofferLink/BoxSpecialofferLink.vue';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeDestroy',
]);

const breadcrumbPath = [
  {
    label: 'ホーム',
    url: '/',
  },
  {
    label: '特別オファー',
    url: '/specialoffer/',
  },
];

const ITEM_MAX_LENGTH = 99;
const API_URL = `/api/wp-json/31corp/v1/special_offer`;
const CATEGORY = 'offer';

async function getDatas(to: Route) {
  const lang = (to.meta && to.meta.lang) ? to.meta.lang : 'ja';
  const api = `${API_URL}?lang=${lang}`;
  const params = {};
  const headers = { Authorization: 'Bearer ' + store.getters['auth/token']};
  const res = await axios.get( api, { params, headers });
  return res;
}

@Component({
  components: {
    LayoutCol1,
    HomeCampaignlistItem,
    NaviArchive,
    BoxSpecialofferLink,
  },
})
export default class CampaignIndex extends Vue {
  private name = 'CampaignIndex';
  private newentriesLabel = '新着記事';
  private title1 = 'CAMPAIGN';
  private title2 = 'SPECIAL CONTENTS';
  private totaleItems: number = 0;
  private length: number = ITEM_MAX_LENGTH;
  private list: PostData[] = [];

  get pageNum(): number {
    return Number(this.$route.query.p);
  }

  get companyName(): string {
    return store.getters['auth/name'];
  }
  get lang(): string {
    return store.getters['auth/lang'];
  }
  get memberName(): string {
    return this.companyName + '様だけの特別オファー';
  }

  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    const res = await getDatas(to);
    store.commit('breadcrumb/setPath', breadcrumbPath);
    next((vm: {
      totaleItems: number,
      list: any,
    }) => {
      vm.totaleItems = Number(res.data.hits);
      vm.list = res.data.offers;
    });
  }

  private async beforeRouteUpdate(to: Route, from: Route, next: any) {
    const res = await getDatas(to);
    this.totaleItems = Number(res.data.hits);
    this.list = res.data.offers;
    next();
  }

  private mounted() {
    this.$store.commit('loaded', true);
  }
}
