
import { Vue, Component, Mixins, Emit } from 'vue-property-decorator';
import Singlepages from '@/mixins/SinglepagesJa';
import LayoutCol1 from '@/components/layout/LayoutCol1.vue';
import axios, {AxiosResponse, AxiosError} from 'axios';
import HomeCampaignlistItem from '@/components/home/HomeCampaignlist/HomeCampaignlistItem.vue';
import $ from 'jquery';
import VueRouter, {Route} from 'vue-router';
import store from '@/store';
import NaviArchive from '@/components/navi/NaviArchive.vue';
import BoxPickup from '@/components/box/BoxPickup/BoxPickup.vue';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeDestroy',
]);

const breadcrumbPath = [
  {
    label: 'ホーム',
    url: '/',
  },
  {
    label: 'おすすめ情報',
    url: '/pickup/',
  },
];

const ITEM_MAX_LENGTH = 10;
const API_URL = `/api/wp-json/31corp/v1/posts`;
const CATEGORY = 'pickup';

async function getDatas(to: Route) {
  const api = API_URL;
  const offset: number = (Number(to.query.p) - 1) * ITEM_MAX_LENGTH;
  const params = {
    cat: CATEGORY,
    offset,
    length: ITEM_MAX_LENGTH,
  };
  const headers = { Authorization: 'Bearer ' + store.getters['auth/token']};
  const res = await axios.get( API_URL, { params, headers });
  return res;
}

@Component({
  components: {
    LayoutCol1,
    HomeCampaignlistItem,
    NaviArchive,
    BoxPickup,
  },
})
export default class CampaignIndex extends Vue {
  private name = 'CampaignIndex';
  private newentriesLabel = '新着記事';
  private title1 = 'CAMPAIGN';
  private title2 = 'SPECIAL CONTENTS';
  private totaleItems: number = 0;
  private length: number = ITEM_MAX_LENGTH;
  private list: PostData[] = [];

  get pageNum(): number {
    return Number(this.$route.query.p);
  }

  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    const res = await getDatas(to);
    store.commit('breadcrumb/setPath', breadcrumbPath);
    next((vm: {
      totaleItems: number,
      list: any,
    }) => {
      vm.totaleItems = Number(res.data.hits);
      vm.list = res.data.datas;
    });
  }

  private async beforeRouteUpdate(to: Route, from: Route, next: any) {
    this.list = [];
    const res = await getDatas(to);
    this.totaleItems = Number(res.data.hits);
    this.list = res.data.datas;
    next();
  }

  private mounted() {
    this.$store.commit('loaded', true);
  }
}
